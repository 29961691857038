const turno = [
  {
    value: 1,
    label: "Matutino",
    text: "Matutino",
  },
  {
    value: 3,
    label: "Vespertino",
    text: "Vespertino",
  },
  {
    value: 5,
    label: "Noturno",
    text: "Noturno",
  },
  {
    value: 6,
    label: "Integral",
    text: "Integral",
  },
];

export { turno };
